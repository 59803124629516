// src/components/AppCreationWizard.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useToast } from '../context/ToastContext';
import { generatePlan, buildApp, getAppStatus } from '../services/api';

const AppTypeSelector = ({ onSelect }) => {
  const appTypes = ['Web App', 'Mobile App', 'Game', 'Desktop App'];
  return (
    <div className="grid grid-cols-2 gap-4">
      {appTypes.map((type) => (
        <button
          key={type}
          onClick={() => onSelect(type)}
          className="p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
        >
          {type}
        </button>
      ))}
    </div>
  );
};

const PromptInput = ({ onSubmit }) => {
  const [prompt, setPrompt] = useState('');
  return (
    <form onSubmit={(e) => { e.preventDefault(); onSubmit(prompt); }}>
      <textarea
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        className="w-full h-32 p-2 border rounded-md"
        placeholder="Describe your app in detail..."
      />
      <button type="submit" className="mt-4 px-4 py-2 bg-primary text-white rounded-md">
        Generate Plan
      </button>
    </form>
  );
};

const PlanReview = ({ plan, onApprove, onRevise }) => {
  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Generated Plan</h3>
      <pre className="bg-gray-100 p-4 rounded-md mb-4 whitespace-pre-wrap">
        {JSON.stringify(plan, null, 2)}
      </pre>
      <div className="flex justify-between">
        <button onClick={onRevise} className="px-4 py-2 bg-gray-300 rounded-md">
          Revise Plan
        </button>
        <button onClick={onApprove} className="px-4 py-2 bg-primary text-white rounded-md">
          Approve and Build
        </button>
      </div>
    </div>
  );
};

const BuildProgress = ({ appId }) => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('building');
  const [buildUrl, setBuildUrl] = useState('');
  const { showToast } = useToast();

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const response = await getAppStatus(appId);
        setStatus(response.data.status);
        if (response.data.status === 'completed') {
          setProgress(100);
          setBuildUrl(response.data.buildUrl);
          showToast('Your app is ready!');
        } else if (response.data.status === 'failed') {
          showToast('App build failed. Please try again.', 'error');
        } else {
          setProgress((prevProgress) => Math.min(prevProgress + 10, 90));
          setTimeout(checkStatus, 2000);
        }
      } catch (error) {
        showToast('Error checking app status', 'error');
      }
    };

    checkStatus();
  }, [appId, showToast]);

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">Building Your App</h3>
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div 
          className="bg-primary h-2.5 rounded-full" 
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p className="mt-2 text-center">{progress}% Complete</p>
      {status === 'completed' && (
        <a 
          href={buildUrl} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="mt-4 inline-block px-4 py-2 bg-primary text-white rounded-md"
        >
          View Your App
        </a>
      )}
    </div>
  );
};

const AppCreationWizard = () => {
  const [step, setStep] = useState(1);
  const [appType, setAppType] = useState(null);
  const [appDescription, setAppDescription] = useState('');
  const [generatedPlan, setGeneratedPlan] = useState(null);
  const [appId, setAppId] = useState(null);
  const { showToast } = useToast();

  const handleAppTypeSelect = (type) => {
    setAppType(type);
    setStep(2);
  };

  const handlePromptSubmit = async (prompt) => {
    setAppDescription(prompt);
    try {
      const response = await generatePlan(appType, prompt);
      setGeneratedPlan(response.data);
      setStep(3);
    } catch (error) {
      showToast('Error generating plan. Please try again.', 'error');
    }
  };

  const handlePlanApproval = async () => {
    try {
      const response = await buildApp(generatedPlan);
      setAppId(response.data.appId);
      setStep(4);
    } catch (error) {
      showToast('Error starting app build. Please try again.', 'error');
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-6">Create Your App</h2>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {step === 1 && (
          <div>
            <h3 className="text-xl font-semibold mb-4">Select App Type</h3>
            <AppTypeSelector onSelect={handleAppTypeSelect} />
          </div>
        )}
        {step === 2 && (
          <div>
            <h3 className="text-xl font-semibold mb-4">Describe Your {appType}</h3>
            <PromptInput onSubmit={handlePromptSubmit} />
          </div>
        )}
        {step === 3 && (
          <PlanReview 
            plan={generatedPlan} 
            onApprove={handlePlanApproval} 
            onRevise={() => setStep(2)} 
          />
        )}
        {step === 4 && appId && (
          <BuildProgress appId={appId} />
        )}
      </motion.div>
    </div>
  );
};

export default AppCreationWizard;