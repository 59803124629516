// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { ToastProvider } from './context/ToastContext';
import Header from './components/Header';
import Login from './components/Login';
import Register from './components/Register';
// import Dashboard from './components/Dashboard';
import PreLaunchLandingPage from './components/pages/PreLaunchLandingPage';
import UserDashboard from './components/UserDashboard';
import PrivateRoute from './components/PrivateRoute';
import SavedPages from './components/SavedPages';
import AnimatedPage from './components/AnimatedPage';
import LandingPage from './components/LandingPage';
import trackActivity, { ActivityTypes } from './services/activityTracker';

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackActivity(ActivityTypes.PAGE_VIEW, { path: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <AuthProvider>
      <ToastProvider>
      <Router>
        <PageViewTracker />
        <div className="flex flex-col min-h-screen">
          {/* <Header /> */}
          <main className="flex-grow bg-gray-100">
            <Routes>
              <Route path="/" element={<AnimatedPage><PreLaunchLandingPage /></AnimatedPage>} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/saved-pages" element={
                <PrivateRoute>
                  <SavedPages/>
                </PrivateRoute>
              }/>
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <AnimatedPage>
                      <UserDashboard />
                    </AnimatedPage>                  
                  </PrivateRoute>
                }
              />
            </Routes>
          </main>
          <footer className="bg-gray-800 text-white text-center py-4">
            © 2024 ComeBuildApps. All rights reserved.
          </footer>
        </div>
      </Router>
      </ToastProvider>
    </AuthProvider>
  );
}

export default App;
