// src/context/ToastContext.js
import React, { createContext, useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const ToastContext = createContext(null);

export const ToastProvider = ({ children }) => {
  const showToast = (message, type = 'success') => {
    toast[type](message, {
      style: {
        borderRadius: '10px',
        background: type === 'error' ? '#FEE2E2' : '#ECFDF5',
        color: type === 'error' ? '#DC2626' : '#059669',
      },
    });
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toaster position="top-right" />
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
