// src/services/activityTracker.js
import api from './api';

const trackActivity = async (action, details = {}) => {
  try {
    await api.post('/user/activity', { action, details, timestamp: new Date().toISOString() });
  } catch (error) {
    console.error('Error tracking activity:', error);
  }
};

export const ActivityTypes = {
  PAGE_VIEW: 'PAGE_VIEW',
  APP_CREATION_STARTED: 'APP_CREATION_STARTED',
  APP_CREATION_COMPLETED: 'APP_CREATION_COMPLETED',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  LOGIN: 'LOGIN',
  LOGIN_FAILED: 'LOGIN_FAILED',
  LOGOUT: 'LOGOUT',
};

export default trackActivity;