// src/components/PricingCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const PricingCard = ({ tier, monthlyPrice, annualPrice, features, isPopular, isAnnual }) => {
  const price = isAnnual ? annualPrice : monthlyPrice;

  return (
    <div className={`bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 ${isPopular ? 'border-4 border-primary' : ''}`}>
      <div className="p-6">
        <h3 className="text-2xl font-semibold text-center mb-4">{tier}</h3>
        <div className="text-center mb-6">
          <span className="text-4xl font-bold">${price}</span>
          <span className="text-gray-500">/{isAnnual ? 'year' : 'month'}</span>
        </div>
        <ul className="space-y-3 mb-6">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <CheckIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
              <span className="flex-grow">{feature.text}</span>
              {feature.tooltip && (
                <Tooltip title={feature.tooltip} position="top" arrow={true}>
                  <InformationCircleIcon className="h-5 w-5 text-gray-400 ml-2 cursor-help" />
                </Tooltip>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="px-6 pb-6">
        <Link
          to="/register"
          className={`block w-full text-center py-2 px-4 rounded-md transition-colors ${
            isPopular
              ? 'bg-primary text-white hover:bg-blue-600'
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          {isPopular ? 'Start Free Trial' : 'Choose Plan'}
        </Link>
      </div>
      {isPopular && (
        <div className="bg-primary text-white text-center py-2">
          Most Popular
        </div>
      )}
    </div>
  );
};

export default PricingCard;