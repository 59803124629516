// src/components/SavedPages.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SavedPages = () => {
  const [savedPages, setSavedPages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSavedPages = async () => {
      try {
        const res = await axios.get('http://localhost:5005/api/pages');
        setSavedPages(res.data);
      } catch (error) {
        console.error('Error fetching saved pages:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchSavedPages();
  }, []);

  if (isLoading) {
    return <div className="text-center">Loading saved pages...</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Saved Pages</h2>
      {savedPages.length === 0 ? (
        <p className="text-gray-600">No saved pages yet.</p>
      ) : (
        <ul className="space-y-4">
          {savedPages.map((page) => (
            <li key={page._id} className="bg-gray-100 p-4 rounded-md">
              <h3 className="text-xl font-semibold mb-2">{page.name}</h3>
              <iframe srcDoc={page.content} title={page.name} className="w-full h-48 border border-gray-300 rounded-md" />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SavedPages;